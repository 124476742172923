export type Service = {
  title: string
  body: string
  isPrimary?: boolean
  order?: number
  iconUrl?: string
}

export const SERVICES: Service[] = [
  {
    title: 'Comprehensive Care',
    body: 'Setting you up for regular cleanings and exams can help prevent the cascade of dental problems associated with plaque and calculus. If left untreated these problems can include bone loss, tooth decay, dental infections, gingivitis, halitosis (bad breath), and eventually tooth loss.',
    isPrimary: true,
    order: 1,
    iconUrl:
      'https://firebasestorage.googleapis.com/v0/b/kreklow-dental.appspot.com/o/icons%2Fcleaning-kit.png?alt=media&token=03d446eb-ede6-45bb-873d-896e83bb6f38',
  },
  {
    title: 'Crowns',
    body: 'Perhaps you have a large cavity or existing filling. Maybe you had a tooth with a root canal or prefer a different esthetic for a tooth. With 360 degree coverage it might be time to consider a crown for that tooth.',
    isPrimary: true,
    order: 2,
    iconUrl:
      'https://firebasestorage.googleapis.com/v0/b/kreklow-dental.appspot.com/o/icons%2Fdental-crown.png?alt=media&token=20dada2d-56f6-434c-b2c4-57777f24e8a4',
  },
  {
    title: 'Nitrous',
    body: 'Feeling a bit anxious about your dental appointments? You are not the only one. That is why we offer nitrous - better known as laughing gas - to those who wish to have it. Nitrous can put even the most nervous at ease during their dental treatments.',
  },
  {
    title: 'Oral Surgery',
    body: 'Despite all of the efforts sometimes a tooth will need to be removed for various reasons. Our goal is to make saying goodbye as painless as possible. However, removal of a single tooth can lead to issues related to your chewing ability, your jaw joint, and shifting teeth, which may have a major impact on your dental health. To avoid this we can discuss tooth replacement options for you.',
  },
  {
    title: 'Dentures',
    body: 'There are many forms of dentures but their purpose is to replace missing teeth. Achieving optimal fit and function is important which can take several appointments. Although they may take some time to get used to over time they can really enhance your smile and function.',
  },
  {
    title: 'Whitening',
    body: 'Let’s put a little sparkle in your smile. With custom trays and prescription whitening you can have a bright white smile that is easy and noninvasive.',
  },
  {
    title: 'Fillings',
    body: 'Looking to change the esthetics of your teeth or have a small cavity? Getting a filling is a very commonly performed procedure to have done at the dental office.',
  },
  {
    title: 'Mouth & Sports Guards',
    body: 'If you have problems with clenching or grinding your teeth, or maybe you are snoring, we can help you get a good night’s sleep with a nightguard. We can also help protect your pearly whites if you are involved in high-impact sports that could result in a chipped or lost tooth. Our mouth guards, night guards, and sports guards are custom made to fit your mouth.',
  },
  {
    title: 'Implants',
    body: 'Missing a tooth due to a cavity or perhaps an accident? An implant is a great option for replacing missing teeth. A specialist will place the implant while we can restore the crown at our office.',
  },
  {
    title: 'Bridge',
    body: 'If you happen to be missing a tooth a bridge might be a great replacement option for you. A bridge usually requires a tooth in front and back of the missing space. The teeth around the missing space will be crowned and a “floating tooth” will span between the two teeth.',
  },
  {
    title: 'Dental Emergencies',
    body: 'Tooth pain or dental emergencies don’t run on a schedule and we understand that. We will work with you to see you as soon as we can to get you out of pain.',
  },
  {
    title: 'Root Canals',
    body: 'Sometimes in order to save a tooth we need to do a root canal. Whether it be from a cavity or a cracked tooth, after running diagnostic testing on the tooth we will determine if you need a root canal.',
  },
  {
    title: 'Aligner Therapy',
    body: 'Looking to straighten up your smile? We offer clear aligners for healthy tooth movement to get that smile you have always wanted. With the ease of brushing, flossing, and no unsightly brackets, aligner therapy could be the right choice for you.',
  },
  {
    title: 'Preventative Care',
    body: 'Our number one goal is for our patients to live a long happy life and that begins here with a healthy mouth. Whether you have periodontal disease or great oral health. Regular cleaning visits can help prevent the accumulation of plaque and calculus. At your cleaning appointments Dr. Kreklow will examine to help prevent tooth loss. They will also check for any abnormal soft tissue findings such as oral cancer. Early detection is the best prevention.',
    isPrimary: true,
    order: 0,
    iconUrl:
      'https://firebasestorage.googleapis.com/v0/b/kreklow-dental.appspot.com/o/icons%2Fbrushing-teeth.png?alt=media&token=1cc0ed4c-f25d-45a9-8fa6-7b895b8af6c9',
  },
]

export const getPrimaryServices = () => {
  return SERVICES.filter((s) => s.isPrimary)
}
