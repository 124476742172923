import './About.scss'
import { Bio, BIOS } from '../../bios'
import Hero from '../hero/Hero'
import { Helmet } from 'react-helmet'
import Hiring from '../hiring/Hiring'

const About = () => {
  const renderAboutBlock = (about: Bio, i: number) => {
    return (
      <div className="about-block" key={i}>
        <div className="about-img">
          <img
            src={about.img_url}
            alt={`${about.name} - Staff member at Gretchen Kreklow Dental Family Dentistry, Grand Rapids MN`}
          />
        </div>
        <div className="details">
          <div className="name secondary-text">{about.name}</div>
          <div className="title">{about.title}</div>
          <div className="bio">
            {about.bio.map((par, i) => (
              <p key={i}>{par}</p>
            ))}
          </div>
        </div>
      </div>
    )
  }

  return (
    <main className="about">
      <Helmet>
        <title>Meet Us | Kreklow Dentistry</title>
        <meta
          name="description"
          content="Dr. Kreklow is a General Dentist servicing patients in Grand Rapids, Minnesota. We're passionate about what we do and offer high quality care in a comfortable and welcoming environment. Learn more about us here at Kreklow Dental."
        />
        <link rel="canonical" href="https://kreklowdental.com/about" />
      </Helmet>
      <Hero
        position="center"
        title="Meet Us"
        // url={
        //   'https://firebasestorage.googleapis.com/v0/b/kreklow-dental.appspot.com/o/staff-photos%2Fstaff-meet-us.jpg?alt=media&token=be3c36a9-c5dd-45c9-bcc7-fe3bcd2ef1b0'
        // }
        url={
          'https://firebasestorage.googleapis.com/v0/b/kreklow-dental.appspot.com/o/staff-photos%2Fsept-2024-updates%2Fteam-sitting.jpg?alt=media&token=3fb88dd0-6cbe-4b23-8b4e-fe61ff9aaa8d'
        }
      />
      <section className="bios">{BIOS.map((bio, i) => renderAboutBlock(bio, i))}</section>
      <Hiring />
    </main>
  )
}

export default About
