import './App.css';
import Header from './components/header/Header';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Services from './components/services/Services';
import Gallery from './components/gallery/Gallery';
import Contact from './components/contact/Contact';
import Footer from './components/footer/Footer';
import About from './components/about/About';
import Home from './components/home/Home';
import ScrollToTop from './components/ScrollToTop/ScrollToTop';

function App() {
    return (
        <Router>
            <ScrollToTop />
            <Header />
            <Switch>
                <Route exact path="/">
                    <Home />
                </Route>
                <Route exact path="/about">
                    <About />
                </Route>
                <Route exact path="/services">
                    <Services />
                </Route>
                {/* <Route path="/gallery">
                    <Gallery />
                </Route> */}
                <Route exact path="/contact">
                    <Contact />
                </Route>
            </Switch>
            <Footer />
        </Router>
    );
}

export default App;
