import './Home.scss'
import { Link } from 'react-router-dom'
import Hero from '../hero/Hero'
import ServiceBlock from '../ServiceBlock/ServiceBlock'
import { getPrimaryServices } from '../../data'
import { Helmet } from 'react-helmet'
import Hiring from '../hiring/Hiring'

const Home = () => {
  const services = getPrimaryServices()
  const heroUrl =
    'https://firebasestorage.googleapis.com/v0/b/kreklow-dental.appspot.com/o/staff-photos%2Fsept-2024-updates%2Fteam-pic.jpg?alt=media&token=c9c40db2-1b6a-4510-b928-a51e8acdb739'
  return (
    <main className="home">
      <Helmet>
        <title>Kreklow Dentistry</title>
        <meta
          name="description"
          content="Dr. Kreklow Family Dentistry in Grand Rapids, MN. Offering quality restorative, prosthodontic, and cosmetic dentistry. Call 218-326-1266 for more info."
        />
        <link rel="canonical" href="https://kreklowdental.com" />
      </Helmet>
      <Hero
        position="center"
        url={heroUrl}
        title="Quality restorative, prosthodontic, and cosmetic dentistry."
        showLogo={false}
        // opacity={true}
      />
      <section className="services-description">
        {services && services.map((s, i) => <ServiceBlock key={i} service={s} />)}
        <Link to="/services" className="button">
          View Our Services
        </Link>
      </section>
      <Hiring />
    </main>
  )
}

export default Home
