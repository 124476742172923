import { FC } from 'react';
import './MobileMenu.scss';
import { useHistory } from 'react-router-dom';
import phone from '../../assets/phone-light.png';
import location from '../../assets/location-light.png';
import mail from '../../assets/mail-light.png';

export type MobileMenuProps = {
    toggleMenu: Function;
    menuOpen: boolean;
}

const MobileMenu: FC<MobileMenuProps> = ({ toggleMenu, menuOpen }) => {
    const history = useHistory();

    const menuClick = (path: string) => {
        toggleMenu(false);
        history.push(path)
    }

    const getClasses = () => {
        return menuOpen ? 'mobile-menu mobile-menu--open' : 'mobile-menu';
    }
    return (
        <section className={getClasses()}>
            <nav aria-label="main mobile">
                <i onClick={() => toggleMenu(false)} className="material-icons">close</i>

                <ul>
                    <li className="menu-item" onClick={() => menuClick('/')}>
                        Home
                    </li>
                    <li className="menu-item" onClick={() => menuClick('/about')}>
                        Meet Us
                    </li>
                    <li className="menu-item" onClick={() => menuClick('/services')}>
                        Services
                    </li>
                    {/* <li className="menu-item" onClick={() => menuClick('/gallery')}>
                        Gallery
                    </li> */}
                    <li className="menu-item" onClick={() => menuClick('/contact')}>
                        Contact
                    </li>
                    <li className="menu-item">
                        <a href="tel:218-326-1266">
                            <img src={phone} alt="Phone Icon" />
                            <span>(218) 326 - 1266</span>
                        </a>
                    </li>
                    <li className="menu-item">
                        <img src={location} alt="Map Icon" />
                        <span>510 NW 2nd Ave, Grand Rapids, MN 55744</span>
                    </li>
                    <li className="menu-item">
                        <a href="mailto:info@kreklowdental.com">
                            <img src={mail} alt="Letter Icon" />
                            <span>info@kreklowdental.com</span>
                        </a>
                    </li>
               
                </ul>
            </nav>
        </section>
    )
}

export default MobileMenu;