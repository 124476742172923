// import React from 'react';
import './Header.scss'
import { Link } from 'react-router-dom'
import { useState } from 'react'
import location from '../../assets/location-dark.png'
import ig from '../../assets/ig-dark.png'
import MobileMenu from '../mobile-menu/MobileMenu'
import MapModal from '../map-modal/MapModal'

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false)
  const [showMapModal, setShowMapModal] = useState(false)

  return (
    <header>
      <section className="desktop-header">
        <div className="logo">
          <Link to="/" aria-label="Home">
            <img
              src="https://firebasestorage.googleapis.com/v0/b/kreklow-dental.appspot.com/o/logo%2Flogo-small.png?alt=media&token=94c48682-194c-4a2f-91a6-bcd1d12b4269"
              alt="Kreklow Dental Logo"
            />
          </Link>
        </div>
        <nav aria-label="main desktop">
          <ul>
            <li>
              <Link to="/about">Meet Us</Link>
            </li>
            <li>
              <Link to="/services">Services</Link>
            </li>
            {/* <li>
                            <Link to="/gallery">Gallery</Link>
                        </li> */}
            <li>
              <Link to="/contact">Contact</Link>
            </li>
            <li onClick={() => setShowMapModal(true)}>
              <img
                src={location}
                alt="Clickable map icon that opens a Google Maps modal with directions to Kreklow Dental"
              />
            </li>
            <li>
              <a
                href="https://www.instagram.com/kreklowdental/"
                target="_blank"
                rel="noreferrer"
                aria-label="Link to Instagram account for Kreklow Dental"
              >
                <img src={ig} alt="Instagram Link" />
              </a>
            </li>
          </ul>
        </nav>
      </section>

      <section className="mobile-header">
        <div className="logo">
          <Link to="/" aria-label="Home">
            <img
              src="https://firebasestorage.googleapis.com/v0/b/kreklow-dental.appspot.com/o/logo%2Flogo-small.png?alt=media&token=94c48682-194c-4a2f-91a6-bcd1d12b4269"
              alt="Kreklow Dental Logo"
            />
          </Link>
        </div>
        <i onClick={() => setMenuOpen(!menuOpen)} className="material-icons">
          menu
        </i>
      </section>
      {menuOpen && <MobileMenu toggleMenu={setMenuOpen} menuOpen={menuOpen} />}
      {showMapModal && <MapModal close={setShowMapModal} />}
    </header>
  )
}

export default Header
