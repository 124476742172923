import './Hiring.scss'
import { FC } from 'react'

const Hiring: FC = () => {
  return (
    <section className="hiring-container">
      <h2>We're hiring!</h2>
      <p>
        Join a team that’s passionate about dental care. Work in a newly remodeled office with
        state-of-the-art technology and a collaborative culture.
      </p>
      <p>
        To apply, please send an email to{' '}
        <a className="hiring-email" href="mailto:info@kreklowdental.com">
          <span>info@kreklowdental.com</span>
        </a>
      </p>
    </section>
  )
}

export default Hiring
