import './Maps.scss';

export type MapsProps = {
    width: string;
    height: string;
}

const Maps = ({width = '600', height = '450'}) => {
    return (
        <iframe
            title='Google Maps'
            width={width}
            height={height}
            loading="lazy"
            allowFullScreen
            src="https://www.google.com/maps/embed/v1/place?key=AIzaSyBltq6olzX_kf6QBM0a8ESUJy8K0vVdnsk
                &q=Rowell+Dr+%26+Keppler+Dr+Family+Dentistry">
        </iframe>
    )
}

export default Maps;