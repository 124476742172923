import { Service } from '../../data';
import './ServiceBlock.scss';
import { FC } from 'react'

export type ServiceBlockProps = {
    service: Service;
    hideIcons?: boolean;
}

const ServiceBlock: FC<ServiceBlockProps> = ({ service, hideIcons }) => {
    return (
        <section className="service-block">
            {!hideIcons && <div className="icon"><img src={service.iconUrl} alt="" /></div>}
            <div className="text">
                <h2 className="secondary-text">{service.title}</h2>
                <p>{service.body}</p>
            </div>
        </section>
    )
}

export default ServiceBlock;