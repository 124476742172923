import './Contact.scss'
import { FormEvent, useState } from 'react'
import { collection, addDoc } from 'firebase/firestore'
import db from '../../config/fb-config'
import Maps from '../maps/Maps'
import Hero from '../hero/Hero'
import ContactBanner from '../contact-banner/ContactBanner'
import { Helmet } from 'react-helmet'
import Hiring from '../hiring/Hiring'

const Contact = () => {
  const [message, setMessage] = useState('')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [phone, setPhone] = useState('')
  const [email, setEmail] = useState('')
  const [isSent, setIsSent] = useState(false)

  const sendEmail = async (e: FormEvent) => {
    e.preventDefault()

    await addDoc(collection(db, 'mail'), {
      message: {
        subject: 'Contact Form Submission',
        html: `<p>Name: ${firstName} ${lastName}</p><p>Phone: ${phone}</p><p>Email: ${email}</p><p>${message}</p>`,
      },
      to: 'info@kreklowdental.com',
    })
    setIsSent(true)
    resetForm()
  }

  const resetForm = () => {
    setMessage('')
    setFirstName('')
    setLastName('')
    setPhone('')
    setEmail('')
  }

  const handlePhoneNumber = (val: string) => {
    const reg = /^[\d -]+$/
    if (!reg.test(val) && val) {
      return
    }
    const valArr = val.split('')
    if (valArr.length > phone.length) {
      if (valArr.length === 4 && valArr[3] !== '-') {
        valArr.splice(3, 0, '-')
      }
      if (valArr.length === 8 && valArr[7] !== '-') {
        valArr.splice(7, 0, '-')
      }
    }
    if (valArr.length === 13) {
      return
    }

    const str = valArr.join('')
    setPhone(str)
  }

  return (
    <main className="contact">
      <Helmet>
        <title>Contact | Kreklow Dentistry</title>
        <meta
          name="description"
          content="We can't wait to meet you! Contact Kreklow Dentistry in Grand Rapids, MN to set up an appointment. Call 218-326-1266 or email info@kreklowdental.com"
        />
        <link rel="canonical" href="https://kreklowdental.com/contact" />
      </Helmet>
      <Hero
        title="We can't wait to meet you!"
        position="center"
        url="https://firebasestorage.googleapis.com/v0/b/kreklow-dental.appspot.com/o/staff-photos%2Fsept-2024-updates%2Fteam-outside.jpg?alt=media&token=67f85a27-3e83-487b-85d5-69375d8437a9"
      />
      <div className="contact-banner-container">
        <ContactBanner />
      </div>

      <h2 className="secondary-text get-in-touch">Questions? Get in touch.</h2>
      <form onSubmit={sendEmail}>
        <div className="input-group">
          <label htmlFor="first-name">First Name</label>
          <input
            id="first-name"
            type="text"
            name="First Name"
            // placeholder="First Name *"
            onChange={(e) => setFirstName(e.target.value)}
            value={firstName}
          />
          <label htmlFor="last-name">Last Name</label>
          <input
            id="last-name"
            type="text"
            name="Last Name"
            // placeholder="Last Name *"
            onChange={(e) => setLastName(e.target.value)}
            value={lastName}
          />
        </div>
        <div className="input-group">
          <label htmlFor="email">Email</label>
          <input
            id="email"
            type="text"
            name="Email"
            // placeholder="Email *"
            onChange={(e) => setEmail(e.target.value)}
            value={email}
          />
          <label htmlFor="phone">Phone</label>
          <input
            id="phone"
            type="text"
            name="Phone"
            // placeholder="Phone *"
            onChange={(e) => handlePhoneNumber(e.target.value)}
            value={phone}
          />
        </div>
        <div className="input-group">
          <label htmlFor="message">Your Message</label>
          <textarea
            id="message"
            className="message"
            name="Your Message"
            // placeholder="Your Message *"
            onChange={(e) => setMessage(e.target.value)}
            value={message}
          />
        </div>
        <button type="submit" className={isSent ? 'button success' : 'button'}>
          {isSent ? 'Submitted!' : 'Submit'}
        </button>
      </form>

      <div className="map">
        <Maps width="100%" height="100%" />
      </div>
      <Hiring />
    </main>
  )
}

export default Contact
