import './Footer.scss';
import ContactInfo from "../contact-info/ContactInfo"
import Hours from "../hours/Hours"

const Footer = () => {
    const year = new Date().getFullYear()
    return (
        <footer className="footer">
            <div className="footer-body">
                <Hours />
                <ContactInfo />
            </div>
           <div className="footer-sub">
                <span>© Copyright {year} Kreklow Dental</span>
                <span>|</span>
                <a target="_blank" rel="noreferrer" href="https://firebasestorage.googleapis.com/v0/b/kreklow-dental.appspot.com/o/pdfs%2Fhippa-privacy-rights.pdf?alt=media&token=293bf5fc-028a-4adb-b19d-cedbbf91d985" >Privacy Policy</a>
               <span>|</span>
               <span> All Rights Reserved </span>
               <span>|</span>
               <span>ADA Member</span>
               <span>|</span>
               <span>MDA Member</span>
               <span>|</span>
               <span>Reveal/Invisalign Provider</span>
            </div>
        </footer>
    )
}

export default Footer;