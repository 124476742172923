import phone from '../../assets/phone-light.png';
import location from '../../assets/location-light.png';
import mail from '../../assets/mail-light.png';
import './ContactInfo.scss';

const ContactInfo = () => {
    return (
        <section className="contact-info flex-center">
            <h2>Contact Us</h2>
            <ul>
                <li>
                    <a href="tel:218-326-1266">
                        <img src={phone} alt="Phone Icon" />
                        <span>(218) 326 - 1266</span>
                    </a>
                </li>
                <li>
                    <div>
                        <img src={location} alt="Map Icon" />
                        <span>510 NW 2nd Ave, Grand Rapids, MN 55744</span>
                    </div>
                </li>
                <li>
                    <a href="mailto:info@kreklowdental.com">
                        <img src={mail} alt="Letter Icon" />
                        <span>info@kreklowdental.com</span>
                    </a>
                </li>
            </ul>
        </section>
    )
}

export default ContactInfo;