import './MapModal.scss';
import { FC } from 'react';
import Maps from '../maps/Maps';

export type MapModalProps = {
    close: Function
}

const MapModal: FC<MapModalProps> = ({close}) => {
    return (
        <section className="map-modal" onClick={() => close(false)}>
            <i onClick={() => close(false)} className="material-icons close-icon">close</i>
            <div className="map-container">
                <Maps />
            </div>
        </section>
    )
}

export default MapModal;