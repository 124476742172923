import './Hero.scss'
import { FC } from 'react'

type HeroProps = {
  url: string
  title?: string
  position?: string
  opacity?: boolean
  showLogo?: boolean
  logoUrl?: string
}

const Hero: FC<HeroProps> = ({
  url = '',
  title = '',
  position = 'bottom',
  opacity = false,
  showLogo = false,
  logoUrl = '',
}) => {
  return (
    <div className="hero" style={{ backgroundImage: `url(${url})`, backgroundPosition: position }}>
      {showLogo && <img src={logoUrl} alt="Kreklow Dental Logo" className="hero-logo" />}
      {title && <h1 className="heading-text secondary-text">{title}</h1>}
      {opacity && <div className="opacity"></div>}
    </div>
  )
}

export default Hero
