import './ContactBanner.scss';
import phone from '../../assets/phone-light.png';
import location from '../../assets/location-light.png';
import clock from '../../assets/clock-light.png';

const ContactBanner = () => {
    return (
        <div className="contact-banner">
            <div className="contact-section">
                <img src={phone} alt="Phone Icon" />
                <h2>Phone Number</h2>
                <a href="tel:218-326-1266">
                    <span>(218) 326 - 1266</span>
                </a>
            </div>
            <div className="contact-section">
                <img src={location} alt="Map Icon" />
                <h2>Location</h2>
                <div>510 NW 2nd Ave</div>
                <div>Grand Rapids, MN 55744</div>
            </div>
            <div className="contact-section">
                <img src={clock} alt="Clock Icon" />
                <h2>Office Hours</h2>
                <ul>
                <li>
                    <span>Mon</span>
                    <span>8:00 am - 5:00 pm</span>
                </li>
                <li>
                    <span>Tue</span>
                    <span>8:00 am - 5:00 pm</span>
                </li>
                <li>
                    <span>Wed</span>
                    <span>8:00 am - 5:00 pm</span>
                </li>
                <li>
                    <span>Thu</span>
                    <span>8:00 am - 5:00 pm</span>
                </li>
                <li>
                    <span>Fri</span>
                    <span>- By Appointment</span>
                </li>
            </ul>
            </div>
        </div>
    )
}

export default ContactBanner;