import './Services.scss'
import { SERVICES } from '../../data'
import Hero from '../hero/Hero'
import ServiceBlock from '../ServiceBlock/ServiceBlock'
import { Helmet } from 'react-helmet'
import Hiring from '../hiring/Hiring'

const Services = () => {
  const serviceGroup1 = SERVICES.slice(0, 3)
  const serviceGroup2 = SERVICES.slice(3, 6)
  const serviceGroup3 = SERVICES.slice(6, 9)
  const serviceGroup4 = SERVICES.slice(9, 12)
  const serviceGroup5 = SERVICES.slice(12)

  return (
    <main className="services">
      <Helmet>
        <title>Services | Kreklow Dentistry</title>
        <meta
          name="description"
          content="Kreklow Dentistry in Grand Rapids, MN provides high quality dental care in a comfortable and welcoming environment for all patients. Browse all the services offered at Kreklow Dental."
        />
        <link rel="canonical" href="https://kreklowdental.com/services" />
      </Helmet>
      <Hero
        title="Services"
        url="https://firebasestorage.googleapis.com/v0/b/kreklow-dental.appspot.com/o/staff-photos%2Fsept-2024-updates%2Fservices-hero.jpg?alt=media&token=3b813389-b472-4cfd-bc1c-667d5d9ca841"
        position="center"
        opacity={false}
      />

      <section className="service-blocks">
        <div className="service-block-img">
          <img
            src="https://firebasestorage.googleapis.com/v0/b/kreklow-dental.appspot.com/o/staff-photos%2Fsept-2024-updates%2Fservices-1.png?alt=media&token=4df84a36-8ca3-4fa3-95c1-fe617f026c1e"
            alt="Kreklow Dental staff member"
          />
        </div>
        <div>
          {serviceGroup1.map((s, i) => (
            <ServiceBlock service={s} hideIcons={true} key={i} />
          ))}
        </div>
      </section>

      <section className="service-blocks">
        <div>
          {serviceGroup2.map((s, i) => (
            <ServiceBlock service={s} hideIcons={true} key={i} />
          ))}
        </div>
        <div className="service-block-img">
          <img
            src="https://firebasestorage.googleapis.com/v0/b/kreklow-dental.appspot.com/o/staff-photos%2Fsept-2024-updates%2Fservices-2.png?alt=media&token=7718650d-2bb0-4132-9658-27f6a8e92333"
            alt="Kreklow Dental staff member"
          />
        </div>
      </section>

      <section className="service-blocks">
        <div className="service-block-img">
          <img
            src="https://firebasestorage.googleapis.com/v0/b/kreklow-dental.appspot.com/o/staff-photos%2Fsept-2024-updates%2Fservices-3.png?alt=media&token=312aab7b-6a1e-4879-8287-ee50949bbdb7"
            alt="Kreklow Dental staff member"
          />
        </div>
        <div>
          {serviceGroup3.map((s, i) => (
            <ServiceBlock service={s} hideIcons={true} key={i} />
          ))}
        </div>
      </section>

      <section className="service-blocks">
        <div>
          {serviceGroup4.map((s, i) => (
            <ServiceBlock service={s} hideIcons={true} key={i} />
          ))}
        </div>
        <div className="service-block-img">
          <img
            src="https://firebasestorage.googleapis.com/v0/b/kreklow-dental.appspot.com/o/staff-photos%2Fsept-2024-updates%2Fservices-4.png?alt=media&token=d4814988-23a0-47da-9474-67ddd43b24a9"
            alt="Kreklow Dental staff member"
          />
        </div>
      </section>

      <section className="service-blocks">
        <div className="service-block-img">
          <img
            src="https://firebasestorage.googleapis.com/v0/b/kreklow-dental.appspot.com/o/staff-photos%2Fsept-2024-updates%2Fservices-5.png?alt=media&token=a5305ae1-28fc-473f-9971-d5312aa13199"
            alt="Dr. Gretchen Kreklow"
          />
        </div>
        <div>
          {serviceGroup5.map((s, i) => (
            <ServiceBlock service={s} hideIcons={true} key={i} />
          ))}
        </div>
      </section>
      <Hiring />
    </main>
  )
}
export default Services
